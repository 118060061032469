
export default {
  props: {
    name: {type: String},
    photo: {type: String},
    initials: {type: String},
    isOnline: {type: Boolean},
  },
  computed: {
    hasAvatar(): boolean {
      return !!this.photo;
    },
  },
};
